<template>
  <router-link :to="url" class="tile" :theme="theme" :data-title="title">
    <div class="img-container">
      <img class="ico" :src="getIcon(ico)" />
    </div>

    <h2>
      {{ title }}
    </h2>

    <img class="arrow" src="@/assets/arrow.svg" />
  </router-link>
</template>

<script>
export default {
  name: "Tile",
  props: {
    title: String,
    theme: String,
    ico: String,
    url: String,
  },

  methods: {
    getIcon: function (ico) {
      return require("../assets/" + ico + ".svg");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.tile {
  box-shadow: -11.7274px 12.565px 11.7274px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 30px 45px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-out;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  top: 0;

  &:hover {
    top: -10px;
    transform: scale(1.03);

    img.arrow {
      animation: bounceArrow 0.5s alternate ease infinite;
    }
  }

  .img-container {
    display: flex;
    transition: all 0.2s ease-out;
    justify-content: center;
    height: 150px;
    margin-bottom: 20px;

    img.ico {
      height: 100%;
      width: 100%;
    }
  }

  img.arrow {
    padding: 10px;
    background: var(--white);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 20px auto 0px;
    box-shadow: 0px 3.35068px 3.35068px rgba(0, 0, 0, 0.25);
  }

  @keyframes bounceArrow {
    100% {
      transform: rotate(-13deg) scale(1.1);
    }
  }

  h2 {
    color: var(--white);
    font-weight: 500;
    text-align: center;
    margin: auto;
  }

  &[theme="blue"] {
    background: linear-gradient(180deg, #16c7ff 67.89%, #0085ff 112.69%);
  }

  &[theme="pink"] {
    background: linear-gradient(180deg, #fb57a5 70.5%, #ff7816 112.69%);
  }

  &[theme="yellow"] {
    background: linear-gradient(180deg, #ffbe16 67.89%, #fbd757 112.69%);
  }
}

@media (max-width: 768px) {
  .tile {
    padding: 20px;
  }
}
</style>
