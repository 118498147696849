<template>
  <main class="loading">
    <Preload />

    <router-view v-slot="{ Component }">
      <transition name="scale" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>

    <!-- <span class="cursor"></span> -->
  </main>
</template>

<script>
import Preload from "@/components/Preload.vue";

export default {
  name: "Lyghtning App",
  mounted() {
    // const cursor = document.querySelector(".cursor");
    // document.addEventListener("mousemove", (event) => {
    //   cursor.style.left = event.clientX + "px";
    //   cursor.style.top = event.clientY + "px";
    // });

    setTimeout(() => {
      document.querySelector("main").classList.add("done");
    }, 1000);
  },

  components: {
    Preload,
  },
};
</script>


<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Jost:wght@700&display=swap");
@import "./css/_variables.css";

main {
  &.loading {
    &:before {
      content: "";
      position: fixed;
      height: 100vh;
      width: 100vw;
      z-index: 15;
      background: url(assets/logo.svg) center 30% no-repeat var(--deep-black);
      transition: all 0.5s ease-out;
      opacity: 1;
      visibility: visible;
    }

    &:not(.done):after {
      content: "";
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 15;
      color: var(--white);
      font-size: 5em;

      animation: loadingScreen 0.75s ease-out forwards;

      @keyframes loadingScreen {
        0% {
          content: ".";
        }
        50% {
          content: "..";
        }
        100% {
          content: "...";
        }
      }
    }

    &.done {
      &:before {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

.cursor {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: linear-gradient(var(--logo-pink), var(--logo-orange));
  box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  transition: all;
  z-index: 10;
}

:root {
  --sidebar-width: 25%;
  --edge-padding: 40px;
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.15s ease;
}

.scale-enter-from,
.scale-leave-to {
  transform: scale(1.01);
  // opacity: 0.9;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  // cursor: none !important;
  margin: 0;
  padding: 0;
  background: linear-gradient(
    90deg,
    var(--deep-black) 50%,
    var(--off-white) 50%
  );
  font-family: var(--font-family-base);

  // a {
  //   cursor: none !important;
  // }
}

.orange {
  color: var(--logo-orange);
}

.pink {
  color: var(--logo-pink);
}

.baby-blue {
  color: var(--baby-blue);
}

.yellow {
  color: var(--yellow);
}

section {
  h1,
  h2 {
    color: initial;
    margin-bottom: 45px;
    margin-top: 0;
  }

  font-size: 20px;
  color: var(--muted-black-text);

  p {
    margin-top: 0;
    &:not(:last-child) {
      margin-bottom: 45px;
    }
    background: var(--white);
    padding: 30px;
    box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
}

.btn {
  border-radius: 15px;
  border: 0;
  background: linear-gradient(var(--logo-orange), var(--logo-pink));
  display: inline-flex;
  text-decoration: none;
  padding: 10px 15px;
  color: var(--white);
  font-weight: bold;
  box-shadow: -11.7274px 12.565px 11.7274px rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease-out;

  &:active {
    transform: scale(0.9);
  }

  &:hover {
    transform: scale(1.02);
  }

  &.text {
    padding: 5px 7px;
  }
}

@media (max-width: 768px) {
  body {
    background: var(--deep-black);
  }
}

.content-column-inner {
  > section {
    position: relative;
    z-index: 1;
    opacity: 0.85;
  }

  &:before {
    content: "";
    position: fixed;
    z-index: 0;
    bottom: 0;
    right: 0;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.1;
  }
}

.photography .content-column-inner:before {
  background-image: url(assets/drone.png);
  height: 539px;
  width: 587px;
}

.web .content-column-inner:before {
  background-image: url(assets/macbook.png);
  height: 471px;
  width: 640px;
}

.design .content-column-inner:before {
  background-image: url(assets/design.png);
  width: 353px;
  height: 464px;
  opacity: 0.05;
}

.contact .content-column-inner:before {
  background-image: url(assets/contact.png);
  height: 315px;
  width: 358px;
}
</style>