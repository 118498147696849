<template>
  <div class="container">
    <div class="nav-column">
      <Menu :hide-service-items="hideServiceItems" />
    </div>

    <div class="content-column">
      <img class="skew" src="@/assets/skew.svg" />

      <div class="content-column-inner">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";

export default {
  name: "MainLayout",
  props: {
    hideServiceItems: Boolean,
  },
  components: {
    Menu,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.container {
  height: 100vh;
  overflow: hidden;
  display: flex;
  max-width: var(--max-app-width);
  margin: auto;

  .nav-column {
    background: var(--deep-black);
    width: var(--sidebar-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0px 40px 40px;
    position: relative;
  }

  .content-column {
    position: relative;
    z-index: 1;
    flex-grow: 1;
    display: flex;
    width: calc(100% - var(--sidebar-width));

    .content-column-inner {
      background: var(--off-white);
      flex-grow: 1;
      padding: var(--edge-padding);
      overflow: visible;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .skew {
      margin-right: -1px;
      height: calc(100% + 15px);
      width: auto;
      margin-top: -15px;
    }

    h1 {
      text-align: center;
      color: var(--muted-black);
    }
  }
}

@media (max-width: 768px) {
  main .container {
    height: auto;
    flex-direction: column;

    .nav-column,
    .content-column {
      width: 100%;
      padding: 0;
    }

    .nav-column {
      padding: 30px 15px;
    }

    .content-column {
      .skew {
        display: none;
      }

      .content-column-inner {
        padding: 50px 20px;
        border-radius: 6px;
        margin-top: -6px;
      }
    }
  }
}
</style>