<template>
  <MainLayout hide-service-items>
    <template v-slot:content>
      <div class="home">
        <section class="intro">
          <h1>Hé! Goed dat je ons gevonden hebt.</h1>
        </section>

        <div class="tiles">
          <Tile
            theme="pink"
            ico="www"
            title="web"
            url="/websites"
          />
          <Tile theme="blue" ico="design" title="design" url="/design" />
          <Tile
            theme="yellow"
            ico="photography"
            title="(drone) fotografie"
            url="/fotografie"
          />
        </div>

        <section class="intro-sequal">
          <p>
            Lyghtning bouwt websites en is er voor verschillende design oplossingen.
            <br />
            Geen standaard template websites, maar alles op maat gemaakt! <br/> 
            Ook gaan we graag op pad met een drone of camera voor unieke beelden.
          </p>
        </section>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import Tile from "@/components/Tile.vue";
import MainLayout from "@/components/MainLayout.vue";

export default {
  name: "Home",
  components: {
    Tile,
    MainLayout,
  },
};
</script>

<style lang="scss" scoped>
.home {
  align-items: center;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  height: 100%;

  h1 {
    margin: 0;
  }
}

.tiles {
  margin-left: -10%;
  display: flex;
  justify-content: space-between;
  width: 110%;

  .tile {
    margin: 0px 30px;
    width: 33.33%;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: 768px) {
  .home {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .intro-sequal {
    order: 2;
    margin: 30px 0px;
  }

  .tiles {
    margin: 0;
    width: 100%;
    flex-direction: column;
    order: 3;

    .tile {
      width: 100%;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}
</style>