<template>
  <router-link to="/">
    <div class="logo" :orientation="orientation">
      <div class="logo-inner">
        <div>
          <img src="@/assets/logo.svg" />
        </div>

        <div class="txt">
          <img src="@/assets/logo-text.svg" />
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "Logo",
  props: {
    orientation: String,
  },
};
</script>

<style>
:root {
  --logo-txt-margin: 10px;
}
</style>

<style lang="scss" scoped>
a {
  display: flex;
}

.logo {
  position: relative;
  z-index: 2;
  display: flex;
  transition: transform 0.1s ease-out;

  .logo-inner {
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &[orientation="horizontal"] {
    .logo-inner {
      flex-direction: row;
    }

    .txt {
      margin: 0px 0px 0px var(--logo-txt-margin);
      display: flex;
    }
  }

  .txt {
    margin: var(--logo-txt-margin) 0px 0px;
  }

  &:hover {
    transform: scale(1.05);
  }
}
</style>