import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Lyghtning - design & development',
      metaTags: [
        {
          name: 'description',
          content: 'Lyghtning.nl bouwt websites en is er voor al jouw design oplossingen.'
        }
      ]
    }
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    // route level code-splitting
    // this generates a separate chunk (Portfolio.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Portfolio" */ '../views/Portfolio.vue'),
    meta: {
      title: 'Portfolio - Lyghtning',
      metaTags: [
        {
          name: 'description',
          content: 'Bekijk een greep uit het portfolio van Lyghtning'
        }
      ]
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (Contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue'),
    meta: {
      title: 'Contact - Lyghtning',
      metaTags: [
        {
          name: 'description',
          content: 'Contact opnemen met Lyghtning'
        }
      ]
    }
  },
  {
    path: '/design',
    name: 'Design',
    // route level code-splitting
    // this generates a separate chunk (Design.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Design" */ '../views/Design.vue'),
    meta: {
      title: 'Design - Lyghtning',
      metaTags: [
        {
          name: 'description',
          content: 'Lyghtning is er voor al jouw design oplossingen'
        }
      ]
    }
  },
  {
    path: '/websites',
    name: 'Websites',
    // route level code-splitting
    // this generates a separate chunk (Web.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Web" */ '../views/Web.vue'),
    meta: {
      title: 'Websites - Lyghtning',
      metaTags: [
        {
          name: 'description',
          content: 'Een toffe, unieke en snelle website laten bouwen?'
        }
      ]
    }
  },
  {
    path: '/fotografie',
    name: 'Photography',
    // route level code-splitting
    // this generates a separate chunk (Photography.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Photography" */ '../views/Photography.vue'),
    meta: {
      title: '(Drone) fotografie - Lyghtning',
      metaTags: [
        {
          name: 'description',
          content: 'Op zoek naar een gave luchtfoto of video van jouw bedrijf of omgeving naar keuze?'
        }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

interface document {
  title: String
}

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    (document.title as any) = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    (document.title as any) = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  (nearestWithMeta.meta.metaTags as any).map((tagDef: any) => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach((tag: any) => document.head.appendChild(tag));

  next();
});

export default router
